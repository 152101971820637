import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconsLoppyJoe = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconsloppyjoe_large.jpg';

const RecipePage = () => (
    <Layout metaTitle="Candy Bacon Hearts | Wright® Brand Bacon"
        metaDescription=""
        metaKeywords=""
    >
        <meta property="og:image" content={imageBaconsLoppyJoe} />
        <link rel="image_src" href={imageBaconsLoppyJoe} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconsLoppyJoe} class='img-responsive' alt='Bacon Sloppy Joe' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Candy Bacon Hearts</h1>

                            <div class="recipe-content">
                                <p >Everyone around your table will feel the holiday joy when you add a little more love to our thick, hearty bacon. And they’ll enjoy the surprise of a touch of sweetness that compliments the smoky, savory flavor of every strip.</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        5&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>6</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> Package
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 <span class="uom"></span>  cup dark brown sugar
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat oven at 375°F.

                </p>
                            <p>
                                2. Par-cook the bacon for 15-17 minutes. Drain the natural juices on a stack of paper towels.

                </p>
                            <p>
                                3. Spread ½-1 teaspoon brown sugar on each piece. Fold the bacon in half and curl the ends together shaping into a heart. Thread a toothpick in the ends to hold them in place. Thread another toothpick at the base of the heart creating a crease. Place the hearts onto a baking pan and cook until crisp about 5-8 minutes. Drain any juices on top of paper towels.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
